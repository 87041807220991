
import store from '@/store'
import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/layout/Index.vue'


const routes = [
   {//选择移动端还是桌面端
    path:'/',
    name:'Index',
    component:Index
  },
   {//员工登录
    path:'/employeeLogin',
    name:'EmployeeLogin',
    component:()=>import('../views/pages/employee/EmployeeLogin.vue')
  },
   {//手机首页
    path:'/webHome',
    name:'WebHome',
    component:()=>import('../views/pages/web/WebHome.vue')
  },
   {//手机生产信息
    path:'/wProduction',
    name:'WProduction',
    component:()=>import('../views/pages/web/production/WProduction.vue')
  },
   {//手机库存信息
    path:'/wWareHouse',
    name:'WWareHouse',
    component:()=>import('../views/pages/web/wareHouse/WWareHouse.vue')
  },
  {//手机设备信息
    path:'/wEquipment',
    name:'WEquipment',
    component:()=>import('../views/pages/web/equipment/WEquipment.vue')
  },
  {//我的信息
    path:'/wMe',
    name:'WMe',
    component:()=>import('../views/pages/web/me/WMe.vue')
  },
  {
    path: '/layout',
    name: 'layout',
    component: ()=>import('../views/layout/LayOut.vue'),
    redirect:'/navigation',
    children:[
      {//导航页
        path:'/navigation',
        name:'Navigation',
        component:()=>import('../views/layout/Navigation.vue')
      },
      {//员工列表
        path:'/employeeShow',
        name:'EmployeeShow',
        component:()=>import('../views/pages/employee/EmployeeShow.vue')
      },
      {//Bom列表
        path:'/bomShow',
        name:'BomShow',
        component:()=>import('../views/pages/production/BomShow.vue')
      },
        {//排名
        path:'/rankding',
        name:'Rankding',
        component:()=>import('../views/pages/components/Ranking.vue')
      },
    ]
  },
   {//投屏展示
    path:'/screen',
    name:'Screen',
    component:()=>import('../views/layout/NavigationOfScreen.vue')
  },
  {//生产计划一个分屏
    path:'/planOneScreen',
    name:'PlanOneScreen',
    component:()=>import('../views/pages/screen/PlanOneScreen.vue')
  },

   {//生产计划2个分屏
    path:'/planTwoScreen',
    name:'PlanTwoScreen',
    component:()=>import('../views/pages/screen/PlanTwoScreen.vue')
  },

    {//生产计划4个分屏
    path:'/planFourScreen',
    name:'PlanFourScreen',
    component:()=>import('../views/pages/screen/PlanFourScreen.vue')
  },

      {//一个生产计划+轮播图
    path:'/planScreenOneItem',
    name:'PlanScreenOneItem',
    component:()=>import('../views/pages/screen/PlanScreenOneItem.vue')
  },

      {//一个生产计划+轮播图（方案2）
    path:'/planScreenOneItem2',
    name:'PlanScreenOneItem2',
    component:()=>import('../views/pages/screen/PlanScreenOneItem2.vue')
  },

   {//排名大屏1
    path:'/indexLarge1',
    name:'IndexLarge1',
    component:()=>import('../views/pages/screen/largeScreen/large1/indexLarge1.vue')
  },

    {//排名大屏2
    path:'/indexLarge2',
    name:'IndexLarge2',
    component:()=>import('../views/pages/screen//largeScreen/large2/indexLarge2.vue')
  },
    {//生产大屏
      path:'/productionLarge',
      name:'ProductionLarge',
      component:()=>import('../views/pages/screen//largeScreen/production/ProductionLarge.vue')
    },
  {//销售大屏
    path:'/saleLarge',
    name:'SaleLarge',
    component:()=>import('../views/pages/screen//largeScreen/sale/SaleLarge.vue')
  },
 {//404页面
    path:'/:catchAll(.*)',
    name:'404',
    component:()=>import('../views/layout/404.vue')
  },
  {//微信扫一扫
    path:'/wXSaoyisao',
    name:'WXSaoyisao',
    component:()=>import('../components/WXSaoyisao.vue')
  },
 
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {
 const employeeAll = store.state.employeeAllStore.dEmployeeAll;
  //判断是否本地存储用户信息
  if (!employeeAll.username) {
 
        if (to.path === '/employeeLogin') {
        next()
         return
        }
      next('/employeeLogin')
  } else {
    console.log("进入测试：",store.state.employeeAllStore.dEmployeeAll)
        next()
    }

 
})

export default router
