export default {
  namespace:true,
  state: {
    dEmployeeAll: (localStorage.getItem('dEmployeeAll')&&(JSON.parse(localStorage.getItem('dEmployeeAll'))))||{},
    dEmployeeAllList:[{},]
  },
  getters: {
     getDEmployeeAll(state) {
      return state.dEmployeeAll
    },
   
  },
  computed: {
    
  },
  mutations: {
    setEmployeeAll(state, uEmployeeAll) {
      state.dEmployeeAll = uEmployeeAll
      localStorage.setItem('dEmployeeAll',JSON.stringify(uEmployeeAll))
    },
    setEmployeeAllList(state, uEmployeeAllList) {
    state.dEmployeeAllList = uEmployeeAllList
    },
  
   
  },
  actions: {
    login(context) {
      localStorage.setItem('dEmployeeAll',JSON.stringify(context.dEmployeeAll))
    }
  },
  modules: {
  }
}
