export default {
  namespace:true,
  state: {
    planScreen: (localStorage.getItem('planScreen') && (JSON.parse(localStorage.getItem('planScreen'))))
      || {equipmentName1:"",equipmentSerial1:"",equipmentName2:"",equipmentSerial2:"",equipmentName3:"",equipmentSerial3:"",equipmentName4:"",equipmentSerial4:""}
  },
  getters: {
     getPlanScreen(state) {
      return state.planScreen;
    },
    
   
  },
  computed: {
    
  },
  mutations: {
    setPlanScreen(state, planScreen) {
      state.planScreen = planScreen
      localStorage.setItem('planScreen',JSON.stringify(planScreen))
    },
   
   
  
   
  },
  actions: {
  },
  modules: {
  }
}
