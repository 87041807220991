import { createStore } from 'vuex'
import employeeAllStore from './modules/employeeAllStore'
import finishedStore from './modules/finishedStore'
import productionPlanStore from './modules/productionPlanStore'
export default createStore({
  state: {
    pageSize: 30,
    baseURL: "https://mayijindie.cn:8095/android",
    staticHosturl: "http://mayijindie.cn:8093",
    baseURL_k3cloud: "http://183.6.103.25:8094/k3cloud/Kingdee.BOS.WebApi.ServicesStub.AuthService.ValidateUser.common.kdsvc",
    scanResultList:(localStorage.getItem('scanDate')&&(JSON.parse(localStorage.getItem('scanDate'))))||[],
  },
  getters: {
    getScanResultList(state) {
     return state.scanResultList;
    }
  },
  mutations: {
    
    
  },
  actions: {
  },
  modules: {
    employeeAllStore,
    finishedStore,
    productionPlanStore,
    
  }
})
