
export default {
  state: {
    DStockUI: {},
  },
  getters: {
    getFinishedUI(state) {
      return state.DStockUI
    }
   
  },
  
  mutations: {
    setFinishedUI(state, FinishedUI) {
      state.DStockUI = FinishedUI
    },
  },
  actions: { 
 
    
  },
}
